<template>
  <div>
    <v-card>
      <v-card-title>Social Sharing
       <v-spacer />
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="imageData"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <a :href="'/mobile/socialSharing/' + item._id + '/' + item.userId + '/edit'" target="_blank">View</a>
        </template>
      </v-data-table>

    </v-card>
  </div>
</template>

<script>
import RestResource from "../../services/dataServiceMobile";
const service = new RestResource();
export default {
  data() {
    return {
      imageData: [],
      search: "",
      headers: [
        { text: "Id", value: "_id", sortable: true },
        { text: "userId", value: "userId", sortable: true },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.$setLoader()
      service.getDataForSocialSharing().then(res => {
        this.imageData = res.data;
        this.$disableLoader()
      });
    }
  }
};
</script>